import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import CancelSubscription from '../AccountSubscription/CancelSubscription';
import UpgradeSubscription from '../AccountSubscription/UpgradeSubscription';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';
import { IUserWebsite } from '../../interfaces/website';
import WebsiteListCardMenuPopover from '../../components/global/ui/popovers/WebsiteListCardMenuPopover';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import { routes } from '../../constants/routes';
import EditWebsite from '../Websites/EditWebsite';
import DeleteWebsite from '../Websites/DeleteWebsite';
import ViewSnippet from '../Websites/ViewSnippet';
import moment from 'moment';
import FlagWebsite from '../Websites/FlagWebsite';
import { IWebsiteSubscription } from '../../interfaces/subscription';

export default function ClientsWebsiteListCard({ data }: { data: IWebsiteSubscription }) {
  const [selected, setSelected] = useState<IUserWebsite | null>(null);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');

  const auth = useAuthUser();
  const { profile } = useContext(OrganizationContext);

  const [editOpen, setEditOpen] = useState<boolean>(false);

  const [cancelOpen, setCancelOpen] = useState<boolean>(false);
  const [upgradeOpen, setUpgradeOpen] = useState<boolean>(false);
  const [flaggingOpen, setFlaggingOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
  const [snippetOpen, setSnippetOpen] = useState<boolean>(false);
  const [menuOptionOpen, setMenuOptionOpen] = useState<boolean>(false);

  return (
    <>
      {editOpen && selected && (
        <EditWebsite website={data.website as IUserWebsite} closeModal={() => setEditOpen(false)} />
      )}

      {cancelOpen && selected && (
        <CancelSubscription
          subscriptionId={data.subscription_id}
          website={data}
          closeModal={() => setCancelOpen(false)}
        />
      )}
      {deleteOpen && selected && (
        <DeleteWebsite website={selected} closeModal={() => setDeleteOpen(false)} />
      )}

      {upgradeOpen && selected && (
        <UpgradeSubscription
          currentPlan={data.plan}
          currentInterval={data.plan_interval}
          subscriptionId={data.subscription_id}
          closeModal={() => setUpgradeOpen(false)}
        />
      )}

      {snippetOpen && selected && (
        <ViewSnippet
          website={data.website as IUserWebsite}
          closeModal={() => setSnippetOpen(false)}
        />
      )}

      {flaggingOpen && selected && (
        <FlagWebsite website={selected} closeModal={() => setFlaggingOpen(false)} />
      )}
      <div
        className={`border rounded-md border-gray-200 cursor-default hover:border-gray-400 bg-white hover:shadow p-3 md:p-3`}>
        <div className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-9 gap-2 lg:gap-5'}>
          <div className={'lg:col-span-5 flex gap-2'}>
            <div>
              <img
                className={'rounded w-6 h-6 object-contain'}
                src={`https://www.google.com/s2/favicons?domain=${data.website?.main_url}&sz=128`}
                alt={''}
              />
            </div>
            <div className={'flex justify-between'}>
              <div className={'w-full'}>
                <div className={'flex gap-2 items-center flex-wrap'}>
                  <h5 className={'font-medium text-base lg:text-sm capitalize truncate'}>
                    {data.website?.name}
                  </h5>
                  {data.website?.ecommerce === 1 && (
                    <>
                      <span className={'w-1 h-1 rounded-full bg-gray-400'}></span>
                      <span className={'font-normal text-sm lg:text-sm text-gray-500'}>
                        e-commerce
                      </span>
                    </>
                  )}
                </div>
                <div className={'flex gap-1 my-1'}>
                  <span>
                    <i className="fi fi-rr-link text-xs"></i>
                  </span>
                  <span className={'text-sm md:text-sm truncate'}>{data.website?.main_url}</span>
                </div>
                <div>
                  <span className={'text-xs'}>
                    Added: {moment(data.website?.created_at).format('ll')}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-span-2'}>
            <div className={'flex flex-col gap-2 items-start md:justify-end lg:justify-start'}>
              <span
                className={`rounded-full px-2 py-1 leading-none text-xs font-medium border ${
                  data.status == 'active'
                    ? 'bg-emerald-100 text-[#008160] border-emerald-100'
                    : 'bg-amber-100 text-amber-600 border-amber-100'
                } uppercase`}>
                {data.status}
              </span>
              <span
                className={
                  'rounded-full px-2 py-1 leading-none text-xs bg-white text-[#008160] border border-[#008160] capitalize'
                }>
                {data.plan_name}
              </span>
            </div>
          </div>

          <div
            className={
              'flex gap-2 justify-between md:justify-end lg:justify-between items-start lg:col-span-2'
            }>
            <div className={'flex gap-2'}>
              {data.website?.verified == 0 &&
                profile.profile_details?.permission !== 'read' &&
                data.status != 'cancelled' && (
                  <Link
                    to={`${routes.SETUP}?step=3&siteId=${data.idsite}&url=${data.website?.main_url}`}
                    className={
                      'rounded flex justify-center items-center text-xs px-3 py-2 bg-[#008160] hover:bg-emerald-600 text-white'
                    }>
                    <span>Verify</span>
                  </Link>
                )}
              {data.website?.verified == 1 && (
                <a
                  href={`${process.env.REACT_APP_API_URL}/index.php?module=Login&action=auth&hash=${
                    auth()?.hash
                  }&key=${token}&idSite=${data.idsite}&account_id=${data.account_id}`}
                  target={'_blank'}
                  className={
                    'rounded shadow text-xs px-3 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                  }
                  rel="noreferrer">
                  <span className={'pt-1'}>
                    <i className="fi fi-ss-chart-histogram text-sm"></i>
                  </span>{' '}
                  <span className={'text-xs'}>View analytics</span>
                </a>
              )}
            </div>
            <div className={'relative'}>
              <button
                type={'button'}
                onClick={() => {
                  setSelected(data.website as IUserWebsite);
                  setMenuOptionOpen(true);
                }}
                className={
                  'edit rounded px-2 py-2.5 flex items-start gap-2 border border-transparent hover:border-gray-500 bg-gray-100 text-gray-500 text-sm'
                }>
                <span>
                  <i className="edit fi fi-rs-menu-dots-vertical text-sm"></i>
                </span>
              </button>
              {menuOptionOpen && (
                <WebsiteListCardMenuPopover
                initialUpgradeOpen={upgradeOpen}
                  setUpgradeOpen={setUpgradeOpen}
                  setDeleteOpen={setDeleteOpen}
                  setEditOpen={setEditOpen}
                  setCancelOpen={setCancelOpen}
                  setSnippetOpen={setSnippetOpen}
                  setFlaggingOpen={setFlaggingOpen}
                  setSelected={setSelected}
                  data={data.website as IUserWebsite}
                  onClickOutside={() => setMenuOptionOpen(false)}
                  show={menuOptionOpen}
                  subscriptionId={data.subscription_id}
                />
              )}
            </div>
          </div>
        </div>
        <div className={'flex'}>
          {profile.profile_details?.super_user == 1 && data.website?.flagged == 1 && (
            <span
              onClick={() => {
                setSelected(data.website as IUserWebsite);
                setFlaggingOpen(true);
              }}
              className={'p-2 flex items-center gap-3 cursor-pointer rounded hover:bg-amber-50'}>
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-amber-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-amber-500"></span>
              </span>
              <span className={'text-sm text-amber-500'}>Suspicious</span>
            </span>
          )}
        </div>
      </div>
    </>
  );
}
