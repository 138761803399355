import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { useSignup } from './useSignup';
import InputWithoutLabel from '../../../components/input/InputWithoutLabel';
import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import RightSectionPlaceholder from '../../../layouts/authentication/RightSectionPlaceholder';
import InputPassword from '../../../components/input/InputPassword';
import AuthFormHeader from '../../../components/form/AuthFormHeader';
import TopHeader from '../../../components/global/TopHeader';
import Error from '../../../components/global/ui/alerts/Error';
import { useContext, useEffect, useState } from 'react';
import Success from '../../../components/global/ui/alerts/Success';
import { useQuery } from '../../../util/global';
import { useSignIn, useSignOut } from 'react-auth-kit';
import { AMPLITUDE_EVENTS, CLIENT_URL, ORGANIZATION_ID } from '../../../constants';
import { useTeamInvites } from '../../main/TeamManagement/useTeamInvites';
import { routes } from '../../../constants/routes';
import { useUserSites } from '../../../hooks/useUserSites';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { useIntercom } from 'react-use-intercom';
import { trackAmplitudeEvent, updateAmplitudeUserId } from '../../../util/amplitudeService';
import logo from '../../../assets/logo.svg';
import { log } from 'console';
declare global {
  interface Window {
    growsumo?: {
      data: {
        name: string;
        email: string;
        customer_key: string;
        partner_key: string;
        ps_partner_key: string;
        ps_xid: string;
        gspk: string;
        gsxid: string;
      };
      createSignup: () => void;
    };
  }
}


export default function Register() {
  const location = useLocation();
  const { signUp, addToCartEvent, errorMessage } = useSignup();
  const {
    validateInvitation,
    loading: validateInvitationLoading,
    errorMessage: validateInvitationError
  } = useTeamInvites();

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const query = useQuery();
  const signOut = useSignOut();
  const signIn = useSignIn();
  const { shutdown } = useIntercom();

  const { loadUserProfile } = useUserSites();
  const { setSelectedOrganization, setProfile } = useContext(OrganizationContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Enter your full name'),
    email: Yup.string().email('Enter a valid email address').required('Email address is required'),
    password: Yup.string()
      .min(8, 'Password character should be longer than 8')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\-\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      )
      .required('Password is required'),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    accept_terms: Yup.boolean().oneOf([true], 'You must agree to the terms and conditions')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      username: '',
      email: query.get('email') ? (query.get('email') as string) : '',
      password: '',
      optIn: true,
      accept_terms: false,
      account_type: 'freemium'
    },
    validationSchema,
    onSubmit: async (values) => handleSignup(values)
  });

  useEffect(() => {
    trackAmplitudeEvent(AMPLITUDE_EVENTS.VISIT_ACCOUNT_CREATION_PAGE);
    shutdown();

    const params = new URLSearchParams(location.search);
    const planParam = params.get('plan');
    const period = params.get('period')

    // adding new search params for partner stack
    const partner_key = params.get('ps_partner_key') || 'emzsby26rc1a';
    const ps_partner_key = params.get('ps_partner_key') || 'emzsby26rc1a';
    const ps_xid = params.get('ps_xid');
    const gsxid = params.get('gsxid');
    const gspk = params.get('gspk');

    localStorage.setItem('partner_key', partner_key);
    localStorage.setItem('ps_partner_key', ps_partner_key);
    if (ps_xid) localStorage.setItem('ps_xid', ps_xid);
    if (gsxid) localStorage.setItem('gsxid', gsxid);
    if (gspk) localStorage.setItem('gspk', gspk);

    if (planParam) {
      signOut();
      localStorage.setItem('plan', planParam);
    } else {
      localStorage.setItem('plan', '1');
    }

    if (period) {
      signOut();
      localStorage.setItem('period', period);
    } else {
      localStorage.setItem('period', 'month');
    }

    document.title = 'Heatmap Register';

    if (params.get('invite')) {
      localStorage.removeItem('email');
      localStorage.removeItem('password');
      localStorage.removeItem(ORGANIZATION_ID);
      localStorage.removeItem(CLIENT_URL);
      signOut();
      if (params.get('invite') && params.get('email')) {
        validateInvitation({
          email: params.get('email') as string,
          invite_token: params.get('invite') as string
        }).then((response) => {
          if (response.result == 'success' && response.additional.shouldRegister == 1) {
            setSuccessMessage(response.msg || response.message);
            setTimeout(() => {
              navigate(
                `${routes.REGISTER}?invite=${params.get('invite')}&email=${params.get('email')}`
              );
            }, 2000);
          } else if (response.result == 'success' && response.additional.shouldRegister == 0) {
            setSuccessMessage(response.msg || response.message);
            navigate(routes.LOGIN);
          }
        });
      }
    }
  }, [location.search]);

  const partnerStackSignUp = (values: FormikValues) => {
    if (window.growsumo) {
      console.log(window.growsumo.data.partner_key);
      window.growsumo.data.name = values.name;
      window.growsumo.data.email = values.email;
      window.growsumo.data.customer_key = values.email;
      window.growsumo.data.partner_key = localStorage.getItem('ps_partner_key') || 'emzsby26rc1a';
      window.growsumo.data.ps_partner_key = localStorage.getItem('ps_partner_key') || 'emzsby26rc1a';
      const ps_xid = localStorage.getItem('ps_xid');
      if (ps_xid) window.growsumo.data.ps_xid = ps_xid;
      const gsxid = localStorage.getItem('gsxid');
      if (gsxid) window.growsumo.data.gsxid = gsxid;
      const gspk = localStorage.getItem('gspk');
      if (gspk) window.growsumo.data.gspk = gspk;
      window.growsumo.createSignup();
    }
  };

  const handleSignup = async (values: FormikValues) => {
    setLoading(true);
    const response = await signUp({
      name: values.name,
      username: values.username,
      email: values.email,
      password: values.password,
      invite: query.get('invite') && query.get('invite'),
      optIn: values.optIn ? 1 : 0,
      accept_terms: values.agree ? 0 : 1,
      account_type: values.account_type
    });
    partnerStackSignUp(values);
    localStorage.setItem('account_type', values.account_type);

    if (response.result == 'error') {
      setLoading(false);
    }

    if (query.get('invite') && response.result == 'success' && response.token) {
      // call amplitude account creation successful event
      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);

      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      setSuccessMessage(response.msg || response.message);

      loadUserProfile({
        auth_token: response.token.token,
        email: response.token.email
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          localStorage.setItem(ORGANIZATION_ID, response.token.account_id);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
          } else if (res.subscription_details.subscription.length === 0) {
            navigate(routes.PLANS);
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: res.subscription_details.subscription.length === 0,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
          } else {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
            navigate(routes.DASHBOARD);
          }
        }
      });
    } else if (
      query &&
      response.result == 'success' &&
      (query.get('plan') == '0' ||
        query.get('plan') == '1' ||
        query.get('plan') == '2' ||
        query.get('plan') == '3' ||
        query.get('plan') == '4' ||
        query.get('plan') == '9'
      )
    ) {
      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);
      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      addToCartEvent({ plan: query.get('plan') as string, token: response.token.token }).then(
        (addToCartRes) => {
          setSuccessMessage(response.msg || response.message);
          loadUserProfile({
            auth_token: response.token.token,
            email: response.token.email
          }).then((res) => {
            if (res) {
              setProfile(res);
              // setSelectedOrganization(res.organizations[0]);
              localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
              if (res.profile_details.super_user == 1) {
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
              } else if (res.subscription_details.subscription.length === 0) {
                navigate(routes.PLANS);
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: res.subscription_details.subscription.length === 0,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
              } else {
                signIn({
                  token: response.token.token,
                  expiresIn: 720,
                  authState: {
                    ...res.profile_details,
                    newUser: false,
                    hash: response.token.hash
                  },
                  tokenType: 'Bearer'
                });
                setLoading(false);
                navigate(routes.DASHBOARD);
              }
            }
          });
        }
      );
    } else if (response.result == 'success') {
      // localStorage.setItem('plan', '1');
      // Call PartnerStack signup after successful registration
      partnerStackSignUp(values);

      trackAmplitudeEvent(AMPLITUDE_EVENTS.COMPLETED_ACCOUNT_CREATION);

      // call amplitude setUserId after successful registration
      updateAmplitudeUserId(response.token.user_id);
      setSuccessMessage('Your account has been created successfully!');
      loadUserProfile({
        auth_token: response?.token.token,
        email: response.token.email
      }).then((res) => {
        if (res) {
          setProfile(res);
          // setSelectedOrganization(res.organizations[0]);
          localStorage.setItem(ORGANIZATION_ID, res.organizations[0].account_id);
          if (res.profile_details.super_user == 1) {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
          } else if (res.subscription_details.subscription.length === 0) {
            navigate(routes.PLANS);
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: res.subscription_details.subscription.length === 0,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
          } else {
            signIn({
              token: response.token.token,
              expiresIn: 720,
              authState: {
                ...res.profile_details,
                newUser: false,
                hash: response.token.hash
              },
              tokenType: 'Bearer'
            });
            setLoading(false);
            navigate(routes.DASHBOARD);
          }
        }
      });
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const planParam = params.get('plan');
    const period = params.get('period');

    if (planParam) {
      localStorage.setItem('plan', planParam);
      // console.log(`Plan ${planParam} saved to localStorage`);
    }
    if (period) {
      localStorage.setItem('period', period);
      // console.log(`Plan ${planParam} saved to localStorage`);
    }
  }, [location.search]);

  return (
    <main className="main-container">
      <TopHeader />
      <div className="grid lg:grid-cols-2 sides-container">
        <section className="pb-16 lg:pb-0 px-5 flex justify-center items-center">
          <form
            id={'account_register_form'}
            onSubmit={(event) => {
              event.preventDefault();
              formik.handleSubmit();
            }}
            className="w-full md:w-1/2 flex flex-col">
            <AuthFormHeader
              title={'Let’s get your free trial started!'}
              description={'Create a new account.'}
            />

            <InputWithoutLabel
              name={'name'}
              value={formik.values.name}
              changeHandler={formik.handleChange}
              placeholder={'Full name'}
              autoFocus={true}
            />
            <p className={'text-sm text-red-500'}>{formik.touched.name && formik.errors.name}</p>

            <InputWithoutLabel
              name={'email'}
              type={'email'}
              value={formik.values.email}
              changeHandler={formik.handleChange}
              placeholder={'Work Email'}
            />
            <p className={'text-sm text-red-500'}>{formik.touched.email && formik.errors.email}</p>
            <InputPassword
              name={'password'}
              value={formik.values.password}
              changeHandler={formik.handleChange}
              placeholder={'Set Password'}
            />
            <p className={'text-sm text-red-500'}>
              {formik.touched.password && formik.errors.password}
            </p>

            <span className="text-sm text-left text-gray-500 mt-1 mb-3">
              8 character minimum, lower and uppercase
            </span>
            <div className={'flex gap-2 mt-2 mb-2 text-gray-500'}>
              <input
                id={'optIn'}
                type={'checkbox'}
                name={'optIn'}
                checked={formik.values.optIn}
                onChange={formik.handleChange}
                className={`outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160] text-sm`}
              />
              <label htmlFor={'optIn'} className={'text-sm'}>
                Yes, I want weekly tips to increase my conversion rate
              </label>
            </div>
            <div className={'flex gap-2 mt-2 mb-2 text-gray-500'}>
              <input
                id={'agree'}
                type={'checkbox'}
                name={'accept_terms'}
                checked={formik.values.accept_terms}
                onChange={formik.handleChange}
                className={`outline-0 w-5 h-5 rounded border border-gray-300 accent-[#008160] text-sm`}
              />
              <label htmlFor={'agree'} className={'text-sm'}>
                I agree to Heatmap’s <a href="https://www.heatmap.com/pages/terms-and-conditions" target='blank' className='underline'>Terms of Service</a>
              </label>
            </div>
            <p className={'text-sm text-red-500'}>
              {formik.touched.accept_terms && formik.errors.accept_terms}
            </p>
            <div className={'mt-5'}>
              <PrimaryButton
                disabled={!formik.values.accept_terms || !formik.values.name || !formik.values.password || !formik.values.email || loading}
                title={loading ? 'Creating an account...' : 'Create account'}
                handler={() => formik.handleSubmit()}
              />
              {errorMessage && <Error value={errorMessage} />}
              {successMessage && <Success value={successMessage} />}

              <p className="text-sm text-center text-black mt-4">
                Already have an account?{' '}
                <Link to={routes.LOGIN} className="text-secondary">
                  Sign in
                </Link>
              </p>
            </div>
          </form>
        </section>
        <section className="right-side-container px-20 hidden lg:flex flex-col justify-center">
          <div className="">
            <img className="mb-4" src={logo} alt="" />
            <p className="font-bold text-[42px] leading-tight text-white mb-4">
              The only heatmap that tells you how to make more money
            </p>
            <p className=" text-white mb-8">
              See revenue insights for every pixel on every page and optimize your e-commerce site based <br /> on buyer behavior
            </p>
            <div className="insight-container">
              <div className="flex items-center relative">
                <img className="image-rounded-full " src="/assets/Photo.jpg" alt="" />
                <img
                  className="image-rounded-full absolute"
                  src="/assets/Photo-1.jpg"
                  alt=""
                  style={{ left: '30px' }}
                />
                <img
                  className="image-rounded-full absolute"
                  src="/assets/Photo-2.jpg"
                  alt=""
                  style={{ left: '60px', zIndex: 5 }}
                />
                <img
                  className="image-rounded-full absolute"
                  src="/assets/Photo-3.jpg"
                  alt=""
                  style={{ left: '90px', zIndex: 6 }}
                />
              </div>
              <p className="align-self-center text-2">And 10,000 more satisfied customers worldwide</p>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}
