import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import { IWebsiteSubscription } from '../../../interfaces/subscription';
import Select from 'react-select';
import { useAppSettings } from '../PlatformSettings/useAppSettings';
import toast, { Toaster } from 'react-hot-toast';

export default function RulesComponent() {

  // ------------------- constants and variables -----------------
  const { profile, loading: websiteLoading } = useContext(OrganizationContext);
  const { loadAppSettings, loading: appSettingsLoading, updateRule } = useAppSettings();
  const [searchWebResults, setSearchWebResults] = useState<IWebsiteSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [featuresList, setFeaturesList] = useState<any>({});
  const { saveWebsiteFeatures } = useAppSettings();
  const [templateData, setTemplateData] = useState<any[]>([]);
  const [selectedRule, setSelectedRule] = useState<Rule | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);


  interface Rule {
    id: string;
    rule_id: number;
    title: string;
    recommendation: string;
    description: string;
    priority: number;
    metadata: object;
    placeholders: any;
    example_recommendations: { bad: string;  good: string  };
    template_id: string;
  }


  // Combined loading state for website and app settings
  const isLoading = websiteLoading || appSettingsLoading || loading;

  // ----------------- maping search results --------------------

  const options = (searchWebResults || [])
    .filter((web) => web.website?.name && web.website?.main_url)
    .map((web) => ({
      value: web.idsite,
      label: web.website?.name || '',
      url: web.website?.main_url || '',
      isDeleted: false,
    }));

  // ------------------- event handlers -----------------

  // ------------------- load user profile -----------------
  useEffect(() => {
    setLoading(true);
    if (profile) {
      setSearchWebResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile.subscription_details?.subscription]);

  // ------------------- load app settings -----------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await loadAppSettings({});
        if (response && response.features_list) {
          setFeaturesList(response.features_list);
        }
      } catch (error) {
        console.error('Error fetching app settings:', error);
      }
    };

    fetchData();
  }, []);

  // ------------------- fetch templates data -----------------
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await fetch("https://6ukidahmx8.execute-api.us-west-2.amazonaws.com/Prod/insight.fetchTemplates");
        const result = await response.json();

        if (result.status === 'success' && result.data) {
          const parsedData = JSON.parse(result.data);
          if (Array.isArray(parsedData)) {
            setTemplateData(parsedData);
          } else {
            console.error('Parsed data is not an array:', parsedData);
          }
        } else {
          console.error('Unexpected response format:', result);
        }
      } catch (error) {
        console.error('Error fetching template data:', error);
      }
    };

    fetchTemplates();
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    if (name === 'example_recommendations_bad') {
      setSelectedRule({
        ...(selectedRule as Rule),
        example_recommendations: {
          ...selectedRule?.example_recommendations,
          bad: value,
          good: selectedRule?.example_recommendations?.good || ''
        },
      });
    } else if (name === 'example_recommendations_good') {
      setSelectedRule({
        ...(selectedRule as Rule),
        example_recommendations: {
          ...selectedRule?.example_recommendations,
          good: value,
          bad: selectedRule?.example_recommendations?.bad || ''
        },
      });
    } else {
      setSelectedRule({
        ...(selectedRule as Rule),
        [name]: value,
        id: selectedRule?.id || '',
      });
    }
  };


  // ############### updated rule click functionality##########
  const handleRuleClick = (rule: any) => {
    const selectedRule = {
      id: rule.template_id,
      template_id: rule.template_id,
      rule_id: rule.rule_id,
      title: rule.title,
      recommendation: rule.recommendation,
      description: rule.description,
      priority: rule.priority,
      metadata: rule.metadata,
      placeholders: rule.placeholders,
      example_recommendations: {
        bad: rule.example_recommendations?.bad || '',
        good: rule.example_recommendations?.good || ''
      },
    };

    setSelectedRule(selectedRule);
    setSelectedTemplateId(rule.template_id);
  };
  //  ######## test function to check for validation ###########
  function isTemplateValid(template: Rule | null) {
    try {
      if (!template) return false;
  
      const { rule_id, template_id, placeholders, recommendation } = template;
  
      if (!rule_id || !template_id) return false;
  
      return Object.keys(placeholders).every(
        placeholder => recommendation.includes(`{${placeholder}}`)
      );
    } catch (error: any) {
      toast.error('Failed to check template validity', error.message);
      return false;
    }
  }
  
  // ########### functionality to update rule #########
  const handleUpdateRule = async () => {
    if (selectedRule && isTemplateValid(selectedRule)) {
      setIsUpdating(true);
      try {
        await updateRule(selectedRule);
        setTemplateData((prevTemplateData) => {
          return prevTemplateData.map((template) => {
            return {
              ...template,
              templates: template.templates.map((rule: any) => {
                if (rule.template_id === selectedRule.id) {
                  return { ...selectedRule };
                }
                return rule;
              }),
            };
          });
        });
      } finally {
        setIsUpdating(false);
      }
    } else {
      toast.error('Error: missing fields or invalid template');
    }
  };



// ########## setting up priority change here ##########
  const handlePriorityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (selectedRule) {
      setSelectedRule({
        ...selectedRule,
        priority: parseInt(event.target.value, 10),
      });
    }
  };
  


  return (
    <>
      <Toaster position="bottom-center" />
      {isLoading ? (
        <div className={'p-5 h-[60vh] flex flex-col justify-center items-center text-center'}>
          <LoadingSpinner color={'text-[#008160]'} />
          <p>Loading...</p>
        </div>
      ) : (
        <div className={'grid grid-cols-2 lg:grid-cols-4 gap-5'}>

          {/* left side container */}
          <div className={'h-[70vh] lg:col-span-2 border-2 border-gray-200 rounded-lg p-5 overflow-y-auto'}>
          <div>
          {templateData.map((template) => (

            <details key={template.id} className=' py-2 rounded-lg hover:cursor-pointer gap-3'>
            <summary className="question py-3 px-4 cursor-pointer select-none text-sm outline-none border font-semibold rounded-lg">{template.element_type}</summary>
            {template.templates.map((rule: any, index: number) => (
          <div 
            key={`${rule.template_id}-${index}`} 
            className={`flex items-center justify-between border p-3 mt-4 rounded-md border-gray-300 hover:bg-gray-50 hover:cursor-pointer gap-3 ${selectedTemplateId === rule.template_id ? 'bg-emerald-50 border-emerald-100' : ''}`}  // Conditional class
            onClick={() => handleRuleClick(rule)}
          >
            <div>
              <p className="text-sm">{rule.description}</p>
            </div>
            <div>
              <span className="fi fi-rr-edit text-lg cursor-pointer hover:text-[#008160] text-[15px]" title='edit rule'></span>
              <span className="fi fi-rr-ban text-lg cursor-pointer hover:text-[red]" title='disable rule' hidden></span>
            </div>
          </div>
        ))}
          </details>
          ))}
        </div>

          </div>

          {/* ####### right side container ########### */}
          <div className={'lg:col-span-2 bg-white border-2 border-gray-200 rounded-lg p-5 relative h-[70vh] overflow-y-auto flex flex-col'}>
            <form action="">
              <div className='mb-4'>
                <label className="block mb-2 text-sm font-semibold text-gray-900">Title</label>
                <input
                  type="text"
                  name="title"
                  value={selectedRule ? selectedRule.title : ''}
                  onChange={handleInputChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-yellow-500 focus:border-gray-500 block w-full p-2.5 dark:placeholder-gray-400"
                  placeholder="Example title here"
                />
              </div>

              <div className='mb-4'>
                <label className="block mb-2 text-sm font-semibold text-gray-900">Rule</label>
                <input
                  type="text"
                  name="description"
                  value={selectedRule ? selectedRule.description : ''}
                  onChange={handleInputChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-gray-500 block w-full p-2.5 dark:placeholder-gray-400"
                  placeholder="Example rule here"
                />
              </div>

              <div className='mb-4'>
                <label className="block mb-2 text-sm font-semibold text-gray-900">Recommendation</label>
                <textarea
                  rows={5}
                  name="recommendation"
                  value={selectedRule ? selectedRule.recommendation : ''}
                  onChange={handleInputChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-gray-500 block w-full p-2.5 dark:placeholder-gray-400"
                  placeholder="Example recommendations here"
                ></textarea>
              </div>

              <div className='mb-4'>
                <p className='text-[14px] font-semibold'> Required Placeholders: </p> {/* Title */}
                <div className=''>
                  {Object.entries((selectedRule as any)?.placeholders || {}).map(([key, value]) => (
                    <li key={key} className='mb-1 text-[14px]'>
                      <span className='font-semibold'>{key}:</span> <span>{String(value)}</span> {/* Key in bold, value as description */}
                    </li>
                  ))}
                </div>
              </div>

              <div className='mb-4'>
                <div className="mx-auto">
                  <label className="block mb-2 text-sm font-semibold text-gray-900">Priority</label>
                  <select
                    name="priority"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-gray-500 block w-full py-2.5 px-0 dark:placeholder-gray-400"
                    value={selectedRule ? selectedRule.priority : ''}
                    onChange={handlePriorityChange}
                  >
                    <option value="" disabled></option> {/* Default empty option */}
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>

              <div className='mb-4'>
                <label className="block mb-2 text-sm font-semibold text-gray-900">Example of good Recommendation</label>
                <textarea
                  rows={5}
                  name="example_recommendations_good"
                  value={selectedRule ? selectedRule.example_recommendations?.good : ''}
                  onChange={handleInputChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-gray-500 block w-full p-2.5 dark:placeholder-gray-400"
                  placeholder="Example of good recommendations here"
                ></textarea>
              </div>
              <div className='mb-4'>
                <label className="block mb-2 text-sm font-semibold text-gray-900">Example of bad Recommendation</label>
                <textarea
                  rows={5}
                  name="example_recommendations_bad"
                  value={selectedRule ? selectedRule.example_recommendations?.bad : ''}
                  onChange={handleInputChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-gray-500 block w-full p-2.5 dark:placeholder-gray-400"
                  placeholder="Example of bad recommendations here"
                ></textarea>
              </div>

              {/* <div className='mb-4 flex justify-end'>
                {isUpdating ? (
                  <div className="rounded px-3 py-2 gap-2 text-white transition-all bg-[#008160] cursor-not-allowed flex items-center">
                    <LoadingSpinner color={'text-white'} />
                  </div>
                ) : (
                  <button
                    type='button'
                    onClick={handleUpdateRule}
                    className={`rounded px-3 py-2 gap-2 text-white transition-all bg-[#008160] hover:bg-emerald-600 cursor-pointer`}
                    disabled={isUpdating}
                  >
                    <span className={'text-sm'}>Apply Changes</span>
                  </button>
                )}
              </div> */}
             
            </form>
            <div className='sticky -bottom-6 bg-white py-4'>
    <div className=' flex justify-end'>
      {isUpdating ? (
        <div className="rounded px-3 py-2 gap-2 text-white transition-all bg-[#008160] cursor-not-allowed flex items-center">
          <LoadingSpinner color={'text-white'} />
        </div>
      ) : (
        <button
          type='button'
          onClick={handleUpdateRule}
          className={`rounded px-3 py-2 gap-2 text-white transition-all bg-[#008160] hover:bg-emerald-600 cursor-pointer`}
          disabled={isUpdating}
        >
          <span className={'text-sm'}>Apply Changes</span>
        </button>
      )}
    </div>
  </div>
          </div>

        </div>
      )}
    </>
  );
}
