import React, { useState, useContext, useEffect } from 'react';
import Modal from '../../../components/global/ui/Modal';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import UserOrganizationsPopover from '../../../components/global/ui/popovers/UserOrganizationsPopover';
import { IPackage, IPlanInterval } from '../../../interfaces/components';
import { usePayment } from '../../../pages/main/Pricing/usePayment';

export default function DetailsModal({
  plan,
  interval,
  formik,
  closeModal,
  onProceed
}: {
  plan: IPackage;
  interval: IPlanInterval;
  formik: any;
  closeModal: () => void;
  onProceed: () => void;
}) {
  const { profile, selectedOrganization, setSelectedOrganization } = useContext(OrganizationContext);
  const [organizationsShow, setOrganizationsShow] = useState<boolean>(false);
  const {loading } =
  usePayment();

  const toggleOrganizationsPopover = () => {
    setOrganizationsShow(!organizationsShow);
  };
  const adminOrWriteAccessOrganizations = profile.organizations.filter(
    (item) => item.permission === 'admin' || item.permission === 'write'
  );

  return (
      <div>
        <div>
          <p className={'text-sm'}>
            {`Confirm your purchase of ${plan.name} plan ${interval.name} subscription.`}
          </p>
          <p className={'text-sm my-2'}>
            Your subscription will be attached to the selected account.
          </p>
          <button
          disabled={true}
            type={'button'}
            onClick={toggleOrganizationsPopover}
            className={`flex justify-between items-center px-3 py-2 mb-2 rounded text-sm text-gray-700 bg-[#ECFDF5]  w-full border`}
          >
            <span className="mr-auto">Current Account</span>
            <span className=' font-semibold'>{selectedOrganization ? selectedOrganization.name : ''}</span>
          </button>
          <div className={'my-2 pt-4 px-3'}>
            <div className=' pb-2'>
              <h5 className={'font-medium text-sm mb-2'}>Summary</h5>
              <div className={'flex justify-between gap-2 text-sm mb-2'}>
                <span>Package</span>
                <span className={'font-medium'}>{plan.name}</span>
              </div>
              <div className={'flex justify-between gap-2 text-sm'}>
                <span>Period</span>
                <span className={'font-medium'}>{interval.name}</span>
              </div>
              <hr className={'my-3'} />
              <div className={'flex justify-between text-sm'}>
                <span>Total Amount: </span>
                <span className={'text-lg font-semibold'}>
                {interval.value === 'month'
                  ? `${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(plan.pricing.month || 0)}/per month`
                  : `${new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(plan.pricing.year || 0)}/per month`}
              </span>
              </div>
            </div>
          </div>
        </div>
        <div className={'grid gap-2'}>
          <div className={'mt-2 flex flex-col-reverse md:flex-row justify-end gap-2'}>
            <div>
              {organizationsShow && (
                <UserOrganizationsPopover
                  hideOrganizations={() => setOrganizationsShow(false)}
                  show={organizationsShow}
                  setSelectedOrganization={setSelectedOrganization}
                  options={adminOrWriteAccessOrganizations}
                  onClickOutside={() => setOrganizationsShow(false)}
                  hideAllAccounts={true}
                />
              )}
            </div>
            <div className='w-[100%] flex justify-between'>

            <button
              type={'button'}
              onClick={() => {
                onProceed();
              }}
              className={`px-4 py-2 rounded text-sm text-gray-700 bg-gray-200 hover:bg-gray-300`}
            >
              Cancel
            </button>
            <button
            type={'button'}
            onClick={() => {
              formik.handleSubmit();
              onProceed();
            }}
            disabled={loading}
            className={`rounded font-medium px-4 py-2 text-sm ${
              loading
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-[#008160] hover:bg-emerald-600 text-white'
            }`}
          >
            {'Yes, proceed'}
          </button>
            </div>
          </div>
        </div>
      </div>
  );
}
