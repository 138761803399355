import DeleteCard from './DeleteCard';
import { useContext, useState } from 'react';
import { ORGANIZATION_ID } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';

export default function PaymentCardItem({
  data,
  setCardAsDefault
}: {
  data: any;
  setCardAsDefault: ({ cardId, customerId }: { cardId: string; customerId: string }) => any;
}) {
  const { fetchOrganizationData, profile } = useContext(OrganizationContext);

  const [deleteCardOpen, setDeleteCardOpen] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);

  const openDeleteCardModal = () => setDeleteCardOpen(true);
  const closeDeleteCardModal = () => setDeleteCardOpen(false);

  const handleSetAsDefault = async (cardId: string, customerId: string) => {
    try {
      const response = await setCardAsDefault({ cardId, customerId });

      if (response?.result === 'success') {
        // refetch organization data
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {['admin', 'write'].includes(profile.profile_details?.permission || '') &&
        deleteCardOpen &&
        profile.subscription_details && (
          <DeleteCard
            customerId={profile.subscription_details.stripe_customer_id}
            cardId={selectedCard as string}
            closeModal={closeDeleteCardModal}
          />
        )}
      <div
        className={`${
          data.is_default_source
            ? 'bg-gradient-to-r from-emerald-500 to-green-300 drop-shadow-lg text-white'
            : 'text-gray-700'
        } border border-gray-200 hover:border-gray-300 hover:cursor-default p-4 rounded-2xl flex flex-col justify-between`}>
        <div className={'flex justify-between items-start'}>
  <div className='h-[130px]'>
    {data.is_default_source && <i className="fi fi-sr-badge-check text-lg text-white"></i>}
  </div>
  <div className={'flex flex-col items-end'}>
    <div className={'flex gap-2'}>
      {['admin', 'write'].includes(profile.profile_details?.permission || '') &&
        profile.subscription_details &&
        profile.subscription_details.subscription &&
        !data.is_default_source &&
        profile.subscription_details.card.length > 1 && (
          <button
            type={'button'}
            onClick={() =>
              handleSetAsDefault(
                data.id,
                profile.subscription_details?.stripe_customer_id as string
              )
            }
            className={
              'rounded border border-gray-400 hover:bg-gray-100 text-gray-500 font-normal px-3 py-1 text-xs'
            }>
            Set as default
          </button>
        )}
      <div className={'flex gap-3'}>
        {profile.profile_details?.permission &&
          ['admin', 'write'].includes(profile.profile_details.permission) &&
          profile.subscription_details &&
          profile.subscription_details.card.length > 1 &&
          !data.is_default_source && (
            <button
              type={'button'}
              onClick={() => {
                setSelectedCard(data.id);
                openDeleteCardModal();
              }}
              className={
                'rounded hover:bg-red-100 text-red-500 font-normal px-3 py-1 text-sm'
              }>
              <span>
                <i className="fi fi-rr-trash text-sm text-red-500"></i>
              </span>
            </button>
          )}
      </div>
    </div>
  </div>
</div>
        <div className={'flex flex-col justify-between gap-2'}>
          <div>
            <p className={'text-lg md:text-2xl font-light'}>
              ****{'  '}****{'  '}****{'  '}
              {data.card.last4}
            </p>
          </div>
          <div className={'flex gap-2 justify-between items-end col-span-2'}>
           {/*  <div>
              <span className={'text-xs md:text-sm font-light'}>EXP:</span>{' '}
              <span className={'text-xs md:text-sm'}>
                {data.card.exp_month}/{data.card.exp_year}
              </span>
            </div> */}
            <div>
            <span className={'text-xs md:text-sm font-light'}>EXP:</span>{' '}
            <span className={'text-xs md:text-sm'}>
              {profile.profile_details?.permission === 'read' ? '**/****' : `${data.card.exp_month}/${data.card.exp_year}`}
            </span>
          </div>
            <img
              src={`/assets/payments/${
                data.card.brand === 'Visa'
                  ? 'visa.svg'
                  : data.card.brand === 'MasterCard'
                  ? 'mastercard.svg'
                  : data.card.brand === 'Discover'
                  ? 'discover.svg'
                  : data.card.brand === 'American Express'
                  ? 'amex.svg'
                  : data.card.brand === 'Diners Club'
                  ? 'diners.svg'
                  : data.card.brand === 'UnionPay'
                  ? 'unionpay.svg'
                  : data.card.brand === 'JCB'
                  ? 'jcb.svg'
                  : ''
              }`}
              className={'w-1/4'}
              alt={''}
            />
          </div>
        </div>
        {/*<div className={'flex justify-between gap-3 mt-2'}>*/}
        {/*  {profile.profile_details?.permission == 'admin' &&*/}
        {/*    profile.subscription_details &&*/}
        {/*    profile.subscription_details.subscription &&*/}
        {/*    !data.is_default_source &&*/}
        {/*    profile.subscription_details.card.length > 1 && (*/}
        {/*      <button*/}
        {/*        type={'button'}*/}
        {/*        onClick={() =>*/}
        {/*          handleSetAsDefault(*/}
        {/*            data.id,*/}
        {/*            profile.subscription_details?.stripe_customer_id as string*/}
        {/*          )*/}
        {/*        }*/}
        {/*        className={*/}
        {/*          'rounded border border-gray-400 hover:bg-gray-100 text-gray-500 font-normal px-3 py-1 text-xs'*/}
        {/*        }>*/}
        {/*        Set as default*/}
        {/*      </button>*/}
        {/*    )}*/}
        {/*</div>*/}
      </div>
    </>
  );
}
