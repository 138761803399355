/* import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import WebsiteListCard from '../../../feature/Websites/WebsiteListCard';
import Layout from '../../../layouts/global/Layout';
import SearchWebsites from '../../../components/form/SearchWebsites';
// import { IUserWebsite } from '../../../interfaces/website';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';
import { IWebsiteSubscription } from '../../../interfaces/subscription';
import { log } from 'console';

export default function Websites() {
  const [searchResults, setSearchResults] = useState<IWebsiteSubscription[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const {
    profile,
    loading: profileLoading,
    setAccountHolderSetupOpen,
    accountHolderSetupOpen
  } = useContext(OrganizationContext);
  
  const subscriptions  = profile.subscription_details?.subscription

  const navigate = useNavigate();

  const currentUrl = useRef(window.location.href);
  const getItemFromUrl = (item: string) => {
    const parsedUrl = new URL(currentUrl.current);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const hashParams = new URLSearchParams(parsedUrl.hash.slice(1));
    return searchParams.get(item) || hashParams.get(item) || "";
  };

  const currentUserWebsite = searchResults?.find(data => data.idsite === +getItemFromUrl('siteId') && data.plan === +getItemFromUrl('planId') && 'can_upgrade' in data && data.can_upgrade === true && getItemFromUrl('is_upgrade') === '1');
  

  useEffect(() => {
    setLoading(true);
    if (profile && profile.subscription_details?.subscription) {
      setSearchResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile]);

  const handleInitiateAddWebsite = () => {
    if (profile.profile_details?.account_holder == 0) {
      setAccountHolderSetupOpen(true);
    } else {
      navigate(
        profile.subscription_details &&
          profile.subscription_details?.subscription.filter((item) => item.idsite == 0).length > 0
          ? routes.SETUP
          : routes.PRICING
      );
    }
  }; */
  import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import LoadingSpinner from '../../../components/global/LoadingSpinner';
import WebsiteListCard from '../../../feature/Websites/WebsiteListCard';
import Layout from '../../../layouts/global/Layout';
import SearchWebsites from '../../../components/form/SearchWebsites';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import PageTitle from '../../../components/global/PageTitle';
import { AccountHolderSetup } from '../../../feature/AccountProfile/AccountHolderSetup';
import { IWebsiteSubscription } from '../../../interfaces/subscription';

export default function Websites() {
  const [searchResults, setSearchResults] = useState<IWebsiteSubscription[] | []>([]);
  const [loading, setLoading] = useState<boolean>(true);
  
  const {
    profile,
    loading: profileLoading,
    setAccountHolderSetupOpen,
    accountHolderSetupOpen
  } = useContext(OrganizationContext);
  
  const subscriptions  = profile.subscription_details?.subscription

  const navigate = useNavigate();
  const location = useLocation();

  const currentUrl = useRef(window.location.href);
  const getItemFromUrl = (item: string) => {
    const parsedUrl = new URL(currentUrl.current);
    const searchParams = new URLSearchParams(parsedUrl.search);
    const hashParams = new URLSearchParams(parsedUrl.hash.slice(1));
    return searchParams.get(item) || hashParams.get(item) || "";
  };

  const currentUserWebsite = searchResults?.find(data => data.idsite === +getItemFromUrl('siteId') && data.plan === +getItemFromUrl('planId') && 'can_upgrade' in data && data.can_upgrade === true && getItemFromUrl('is_upgrade') === '1');

/*   useEffect(() => {
    const checkAndUpdateToken = () => {
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      
      if (token) {
        localStorage.setItem('_auth', token);
       
      }
    };

    checkAndUpdateToken();
  }, [location, navigate]); */

  useEffect(() => {
    setLoading(true);
    if (profile && profile.subscription_details?.subscription) {
      setSearchResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile]);

  const handleInitiateAddWebsite = () => {
    if (profile.profile_details?.account_holder == 0) {
      setAccountHolderSetupOpen(true);
    } else {
      navigate(
        profile.subscription_details &&
          profile.subscription_details?.subscription.filter((item) => item.idsite == 0).length > 0
          ? routes.SETUP
          : routes.PRICING
      );
    }
  };


  return (
    <Layout>
      {accountHolderSetupOpen && (
        <AccountHolderSetup closeModal={() => setAccountHolderSetupOpen(false)} />
      )}
      <PageTitle title={'Websites'} />
      <div>
        <div className={'flex items-start justify-between gap-5'}>
          <SearchWebsites
            websites={
              profile && (profile.subscription_details?.subscription as IWebsiteSubscription[])
            }
            setSearchResults={setSearchResults}
          />

        {profile && profile.profile_details?.super_user !== 1 && (
        <div>
                  {profile &&
                    profile.subscription_details &&
                    profile.subscription_details?.subscription.length > 0 && (
                      <div className={'hidden lg:flex gap-3'}>
                        <button
                          onClick={handleInitiateAddWebsite}
                          className={
                            'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] hover:bg-emerald-600 text-white'
                          }>
                          <span>
                            <i className="fi fi-rr-plus text-xs"></i>
                          </span>
                          <span className={'text-xs'}>Add a website</span>
                        </button>
                      </div>
                    )}
        </div>
        )}

        </div>
        <div className={'my-3 flex flex-col gap-2 h-[70vh] overflow-auto slim-scroll pr-2'}>
          {loading ? (
            <div className={'p-5 h-[70vh] flex flex-col justify-center items-center text-center'}>
              <LoadingSpinner color={'text-[#008160]'} />
              <p>Loading websites...</p>
            </div>
          ) : profile &&
            profile.subscription_details?.subscription &&
            profile.subscription_details?.subscription?.filter((web) => web.website).length > 0 &&
            searchResults.filter((web) => web.website).length > 0 ? (
            searchResults
              .filter((web) => web.website)
              .map((item, index) => <WebsiteListCard data={item} key={index} currentUserWebsite={currentUserWebsite}
              shouldUpgrade={item === currentUserWebsite}  />)
          ) : !profileLoading &&
            profile.subscription_details?.subscription &&
            profile.subscription_details?.subscription?.filter((web) => web.website).length == 0 ? (
            <div className={'h-[60vh] flex flex-col justify-center items-center text-center'}>
              <h4 className={'font-medium mt-5'}>No website found</h4>
              {/*{profile.profile_details?.permission !== 'read' && (*/}
              <div className={'mt-3 flex'}>
                <button
                  type={'button'}
                  onClick={handleInitiateAddWebsite}
                  className={
                    'rounded px-4 py-2 flex items-center gap-2 bg-[#008160] text-white font-medium'
                  }>
                  <span>
                    <i className="fi fi-rr-plus text-xs"></i>
                  </span>
                  <span className={'text-xs'}>Add a website</span>
                </button>
              </div>
              {/*)}*/}
            </div>
          ) : null}
        </div>
        <Link
          to={routes.PRICING}
          className={
            'fixed lg:hidden bottom-5 pt-1 right-5 w-14 h-14 shadow-md rounded-full bg-[#008160] hover:bg-emerald-600 flex justify-center items-center text-white'
          }>
          <i className="fi fi-rr-plus text-xl"></i>
        </Link>
      </div>
    </Layout>
  );
}
