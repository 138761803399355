import { useContext, useEffect, useState } from 'react';
import { OrganizationContext } from '../../contexts/OrganizationContext';
import LoadingSpinner from '../../components/global/LoadingSpinner';
import { IWebsiteSubscription } from '../../interfaces/subscription';
import Select, { ActionMeta, MultiValue } from 'react-select';
import WebsiteDetailsModal from './PopupModal';
import { useAppSettings } from '../../pages/main/PlatformSettings/useAppSettings';
import toast, { Toaster } from 'react-hot-toast';

export default function FeatureManagement() {

  // ------------------- constants and variables -----------------
  const { profile, loading: websiteLoading } = useContext(OrganizationContext);
  const { loadAppSettings, loading: appSettingsLoading, errorMessage } = useAppSettings();
  const [searchWebResults, setSearchWebResults] = useState<IWebsiteSubscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedOptions, setSelectedOptions] = useState<{ value: number; label: string; url: string; isDeleted?: boolean }[]>([]);
  const [selectedWebsiteDetails, setSelectedWebsiteDetails] = useState<IWebsiteSubscription | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [websiteDetails, setWebsiteDetails] = useState<IWebsiteSubscription | null>(null);
  const [featuresList, setFeaturesList] = useState<any>({});
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<{ idsite: number; name: string }[]>([]);
  const [idsites, setIdsites] = useState<string>('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { saveWebsiteFeatures } = useAppSettings();
  // Combined loading state for website and app settings
  const isLoading = websiteLoading || appSettingsLoading;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

// State to track whether user has confirmed to proceed with apply to all
const [proceedApplyToAll, setProceedApplyToAll] = useState(false);

  // ----------------- maping search results --------------------

    const options = (searchWebResults || [])
    .filter((web) => web.website?.name && web.website?.main_url)
    .map((web) => ({
      value: web.idsite,
      label: web.website?.name || '',
      url: web.website?.main_url || '',
      isDeleted: false,
    }));

  // ------------------- event handlers -----------------

  // ------------------- load user profile -----------------
  useEffect(() => {
    setLoading(true);
    if (profile) {
      setSearchWebResults(profile.subscription_details?.subscription as IWebsiteSubscription[]);
      setLoading(false);
    }
  }, [profile.subscription_details?.subscription]);

  // ------------------- load app settings -----------------
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await loadAppSettings({});
        if (response && response.features_list) {
          setFeaturesList(response.features_list);
        }
      } catch (error) {
        console.error('Error fetching app settings:', error);
      }
    };

    fetchData();
  }, []);


  // ------------------- functions -----------------

  // ------------------- select functions -----------------
 /*  const handleSelectChange = (newValue: MultiValue<{ value: number; label: string; }>) => {
    setSelectedOptions(newValue as { value: number; url: string; label: string; }[]);
    setWebsiteDetails(searchWebResults.find((web) => web.idsite === newValue[newValue.length - 1]?.value) || null);
  }; */

  const handleSelectChange = (newValue: MultiValue<{ value: number; label: string; }>) => {
    setSelectedOptions(newValue as { value: number; url: string; label: string; }[]);
    const selectedWebsite = searchWebResults.find((web) => web.idsite === newValue[newValue.length - 1]?.value);
    setWebsiteDetails(selectedWebsite || null);
  };

  // -------------------open modal function -----------------
  const handleOpenModal = (option: { value: number; label: string }) => {
    const selectedWeb = searchWebResults.find((web) => web.idsite === option.value);
    setSelectedWebsiteDetails(selectedWeb || null);
    setShowModal(true);
  };

  // ------------------- close modal function -----------------
  const handleModalClose = () => {
    setShowModal(false);
  };

  // ------------------- checkbox function -----------------
  const toggleCheckbox = (name: string) => {
    setSelectedCheckboxes(prevState => {
      const isChecked = prevState.some(item => item.name === name);
      if (isChecked) {
        return prevState.filter(item => item.name !== name);
      } else {
        return [...prevState, { idsite: 0, name: name }];
      }
    });
  };

  // ------------------- save changes function -----------------
  const handleSaveChanges = async () => {
    const selectedIdsites = selectedOptions.map(option => option.value).join(',');
    const selectedFeatures = selectedCheckboxes.map(item => item.name).join(',');

    try {
      const response = await saveWebsiteFeatures({
        idsite: selectedIdsites,
        features_list: selectedFeatures,
        option : 'single',
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  // ------------------- apply to all function -----------------
  const handleApplyAll = async () => {
    const allIdsites = [...new Set(searchWebResults.map((web) => web.idsite))].join(',');
    const selectedFeatures = selectedCheckboxes.map((item) => item.name).join(',');

    try {
      const response = await saveWebsiteFeatures({
        idsite: allIdsites,
        features_list: selectedFeatures,
        option : 'all',
      });
      console.log(response);
      setShowConfirmationModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  // useEffect to enable/disable button based on conditions
  useEffect(() => {
    setIsButtonDisabled(selectedOptions.length === 0 || selectedCheckboxes.length === 0);
  }, [selectedOptions, selectedCheckboxes]);

  // ------------------- cancel function -----------------
  const handleCancel = () => {
    setSelectedOptions([]);
    setSelectedCheckboxes([]);
  };

  return (
    <>
       <Toaster position="bottom-center" /> 
       {isLoading ? (
      <div className={'p-5 h-[60vh] flex flex-col justify-center items-center text-center'}>
        <LoadingSpinner color={'text-[#008160]'} />
        <p>Loading...</p>
      </div>
    ) : (
      <div className={'grid grid-cols-2 lg:grid-cols-4 gap-5'}>

        {/* left side container */}
        <div className={'h-[70vh] lg:col-span-2 bg-gray-50 border-2 border-gray-200 rounded-lg p-5 overflow-y-auto'}>

          {/* dropdown element / search bar */}
          <Select
            isMulti
            value={selectedOptions.filter((option) => !option.isDeleted)}
            onChange={handleSelectChange}
            options={options}
            placeholder="search websites"
          />

          {/* area to popula website list when selected */}
          {selectedOptions && selectedOptions.length > 0 && (
            <div>
              {selectedOptions.map((option) => (
                !option.isDeleted && (
                  <div
                    key={option.value}
                    className="flex items-center justify-between border p-3 mt-4 rounded-md drop-shadow-md border-gray-300 hover:bg-emerald-50 hover:border-emerald-500 gap-3"
                  >
                    <div>
                      <h4 className="text-md">{option.label}</h4>
                      <p className="text-sm">{option.url}</p>
                    </div>
                    <div>
                      <span
                        className="fi fi-rr-eye text-xl cursor-pointer hover:text-[#008160] mr-3"
                        onClick={() => handleOpenModal(option)}
                      ></span>
                      <span
                        className="fi fi-rr-trash text-xl cursor-pointer hover:text-[red]"
                        onClick={() => setSelectedOptions(selectedOptions.filter((opt) => opt.value !== option.value))}
                      ></span>
                    </div>
                  </div>
                )
              ))}

              {/* popup Modal to show selected website details */}
              {showModal && (
                <WebsiteDetailsModal
                  websiteDetails={selectedWebsiteDetails}
                  onClose={handleModalClose}
                />
              )}
            </div>
          )}
        </div>

        {/* right side container */}
        <>
          <div className={' lg:col-span-2 bg-white border-2 border-gray-200 rounded-lg p-5 relative h-[70vh] '}>
            <h4 className={'font-semibold text-lg mb-2'}>Features Settings</h4>

            {/* Render checkboxes for each feature */}
            {Object.keys(featuresList).map((featureKey) => (
              <div key={featureKey} className="flex mb-4">
                <input
                  id={`checkbox-${featureKey}`}
                  type="checkbox"
                  checked={selectedCheckboxes.some(item => item.name === featuresList[featureKey].name)}
                  onChange={() => toggleCheckbox(featuresList[featureKey].name)}
                  className="mr-3 bg-gray-100 border-gray-300 rounded  dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                />
                <label htmlFor={`checkbox-${featureKey}`} className="mx-3 text-sm font-medium text-gray-900 cursor-pointer">
                  <span className={'font-semibold'}>{featuresList[featureKey].name} :</span> {featuresList[featureKey].description}
                </label>
              </div>
            ))}
            {/* Absolute positioned buttons */}
            <div className="w-[93%] absolute bottom-5 flex justify-between">
              <div>
              {/* Apply to all button */}
              <button
                // onClick={handleApplyAll}
                onClick={() => {
                  setShowConfirmationModal(true);
                }}
                disabled={selectedCheckboxes.length === 0}
                className={`rounded px-3 py-2  gap-2 text-white transition-all  ${loading
                    ? 'bg-[#559383] cursor-progress'
                    : selectedCheckboxes.length === 0
                      ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                      : 'bg-[#008160] hover:bg-emerald-600 cursor-pointer'
                  }`} >
                <span className={'text-sm'}>Appy to All</span>
              </button>

                 {/* Confirmation Modal */}
        {showConfirmationModal && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50">
            <div className="bg-white p-5 rounded-lg shadow-lg">
              <p className="text-sm font-semibold mb-10">You are about to apply this features to all sites. <br /> Do you want to proceed?</p>
              <div className="flex justify-end">
                <button
                  onClick={() => setShowConfirmationModal(false)}
                  className="bg-gray-300 text-sm text-gray-600 hover:bg-gray-400 px-4 py-2 rounded mr-3">
                  Cancel
                </button>
                <button
                  onClick={handleApplyAll}
                  className="bg-[#008160] text-sm hover:bg-emerald-600 text-white px-4 py-2 rounded">
                  Proceed
                </button>
              </div>
            </div>
          </div>
        )}
              </div>

              <div className=''>
              {/* cancel button */}
              <button
                onClick={handleCancel}
                disabled={selectedOptions.length === 0 && selectedCheckboxes.length === 0}
                className={`rounded px-3 py-2  gap-2 mr-2 text-white transition-all  ${loading
                    ? 'bg-[#DC2626] cursor-progress'
                    : selectedOptions.length === 0 && selectedCheckboxes.length === 0
                      ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                      : 'bg-[#DC2626] hover:bg-[crimson]/80 cursor-pointer'
                  }`}>
                <span className={'text-sm'}>Cancel</span>
              </button>
              {/* save changes button */}
              <button
                onClick={handleSaveChanges}
                disabled={isButtonDisabled}
                className={`rounded px-3 py-2 gap-2 text-white transition-all ${loading
                    ? 'bg-[#559383] cursor-progress'
                    : isButtonDisabled
                      ? 'bg-gray-300 text-gray-600 cursor-not-allowed'
                      : 'bg-[#008160] hover:bg-emerald-600 cursor-pointer'
                  }`}>
                <span className={'text-sm'}>Save Changes</span>
              </button>
              </div>

            </div>
          </div>
        </>

      </div>
      )}
    </>
  );
}
